body {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

svg {
  width: 100vmin;
  height: 100vmin;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
